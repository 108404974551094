import React from "react"
import styled from "styled-components"

import PageWrapper from "../../../styles/PageWrapper"
import Seo from "../../../components/seo"

import Finder from "../../../components/Finder"

const FinderPageWrapper = styled(PageWrapper)`
  padding: 0;
`

const FinderPage = () => (
  <FinderPageWrapper>
    <Seo title="Find a f'real - Maverik" />
    <Finder storeFilter={`Maverik`} />
  </FinderPageWrapper>
)

export default FinderPage
